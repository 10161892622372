import React from 'react';

import { GatsbySeo } from 'gatsby-plugin-next-seo/lib';
import { useIntl } from 'gatsby-plugin-intl';

import NotFound from '@components/not-found/404.component';

// tslint:disable no-default-export
export default () => {
  const intl = useIntl();

  return (
    <>
      <GatsbySeo
        nofollow={true}
        noindex={true}
        title={`Avionaut - ${intl.formatMessage({ id: 'metaTitle__404' })}`}
      />
      <NotFound/>
    </>
  );
};

import React, { FC } from 'react';

import { useIntl } from 'gatsby-plugin-intl';

import { PageType } from '@constants/pageType.constants';

import { Button } from '../button/Button';

import Background from '../../assets/images/home-video-cover.jpg';
import s from './404.module.scss';

const NotFound: FC = () => {
  const intl = useIntl();

  return (
    <div className={s.notFound}>
      <span
        className={s.notFound__code}
        style={{
          backgroundImage: `linear-gradient(90deg, rgba(255,255,255,0) 30%, #000000 60%), url(${Background})`,
        }}>
        404
      </span>
      <span className={s.notFound__oops}>{intl.formatMessage({ id: 'notFound__oops' })}</span>
      <span className={s.notFound__explanation}>{intl.formatMessage({ id: 'notFound__explanation' })}</span>
      <Button pageType={PageType.START}>
        {intl.formatMessage({ id: 'notFound__homepage' })}
      </Button>
    </div>
  );
};
export default NotFound;
